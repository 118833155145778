
import { Vue, Component } from "vue-property-decorator";
import { SocketEventType } from "@/modules/core/sockets";

@Component
export default class SocketClientMixin extends Vue {
  // ---------------------------------------------------------------------------
  // METHODS
  // ---------------------------------------------------------------------------

  /**
   * Initializes the connection with svc-socket using socketClient
   * After connected will send a subscribe event to the socket service to create & join a
   * room for the facility in order to receive/send notifications
   * about events related with the current facility
   */
  updateSocket(moduleName) {
    const facility = this.$store.getters["facilities/getCurrentFacility"];
    if (!facility) {
      throw new Error(
        "Something went wrong getting info to update SocketClient"
      );
    }
    this.$socketClient
      .waitUntilAvailable()
      .then(() => {
        this.$socketClient.emit(SocketEventType.SetSource, moduleName);
        this.$socketClient.emit(SocketEventType.Subscribe, facility.uuid);
      })
      .catch(console.error);
  }

  upsertPackoutGoods(uuid: string) {
    this.$socketClient.emit(SocketEventType.Upsert, {
      table: "growops_packout_goods",
      uuid,
    });
  }

  onUpsertPackoutGood(callback: () => void) {
    this.$socketClient.waitUntilAvailable().then(() =>
      this.$socketClient.onUpsert(async (payload: ItemPayload) => {
        if (payload.table === "growops_packout_goods") {
          const packoutGoodUuid = payload.uuid;
          if (packoutGoodUuid === this.uuid) {
            callback?.();
          }
        }
      })
    );
  }

  onUpsertSharedUsers(callback: () => void) {
    this.$socketClient.waitUntilAvailable().then(() =>
      this.$socketClient.onUpsert(async (payload: ItemPayload) => {
        if (
          payload.table === "shared_users" &&
          payload.uuid === this.$root.currentUserId
        ) {
          callback?.();
        }
      })
    );
  }
}
